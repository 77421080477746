import React, { useState } from "react"

export default () => {
  const [powerPoints, setPowerPoints] = useState(0);
  const [coins, setCoins] = useState(0);
  const [currentLevel, setCurrentLevel] = useState(0);
  
  const renderNumberInput = (value, setValue, placeholder) => (
    <div>
      <label htmlFor={placeholder}>{placeholder}</label>
      <input id={placeholder} type="number" placeholder={placeholder} value={value} onChange={e => {
        setValue(e.target.value)
      }}/>
    </div>
  )
  const calculatedPowerPoints = calcPowerPoints(currentLevel, powerPoints, coins)
  return (
    <div>
      {renderNumberInput(currentLevel, setCurrentLevel, 'Level')}
      {renderNumberInput(powerPoints, setPowerPoints, 'Powerpoints')}
      {renderNumberInput(coins, setCoins, 'Coins')}
      <div>
        <p>Max level: {calculatedPowerPoints.maxLevel} {calculatedPowerPoints.maxLevel > currentLevel ? `(up ${ calculatedPowerPoints.maxLevel - currentLevel} levels)` : '(same level)'}</p>
        <p>Remaining powerpoints: {calculatedPowerPoints.remainingPowerpoints} (total cost {powerPoints - calculatedPowerPoints.remainingPowerpoints} powerpoints)</p>
        <p>Remaining coins: {calculatedPowerPoints.remainingCoins} (total cost {coins - calculatedPowerPoints.remainingCoins} coins)</p>
        {calculatedPowerPoints.details.length > 0 && <p>More details:</p>}
        {calculatedPowerPoints.details.map((detail, index) => (
          <p key={index}>{detail}</p>
        ))}
      </div>
    </div>
  )
  }

const calcPowerPoints = (currentLevel, totalPowerpoints, totalCoins) => {
  const levelRequirements = [
    {
      level: 2,
      pp: 20,
      coins: 20
    }, {
      level: 3,
      pp: 30,
      coins: 35
    }, {
      level: 4,
      pp: 50,
      coins: 75
    }, {
      level: 5,
      pp: 80,
      coins: 140
    }, {
      level: 6,
      pp: 130,
      coins: 290
    }, {
      level: 7,
      pp: 210,
      coins: 480
    }, {
      level: 8,
      pp: 340,
      coins: 800
    }, {
      level: 9,
      pp: 550,
      coins: 1250
    }
  ]

  return levelRequirements.reduce((acc, nextLevelRequirements) => {
    if (nextLevelRequirements.level - 1 === acc.maxLevel) {
      const hasEnoughPowerpoints = nextLevelRequirements.pp <= acc.remainingPowerpoints
      const hasEnoughCoins = nextLevelRequirements.coins <= acc.remainingCoins

      if (!hasEnoughPowerpoints) {
        acc.details.push(`not enough powerpoints, require: ${nextLevelRequirements.pp} powerpoints, remaining: ${acc.remainingPowerpoints} powerpoints, you need ${nextLevelRequirements.pp - acc.remainingPowerpoints} powerpoints for next level`)
      }

      if (!hasEnoughCoins) {
        acc.details.push(`not enough coins, require: ${nextLevelRequirements.coins} coins, remaining: ${acc.remainingCoins} coins, you need ${nextLevelRequirements.coins - acc.remainingCoins} coins for next level`)
      }

      if (hasEnoughCoins && hasEnoughPowerpoints) {
        acc.maxLevel++
        acc.remainingPowerpoints -= nextLevelRequirements.pp
        acc.remainingCoins -= nextLevelRequirements.coins
      }
    }
    return acc
  }, {
    maxLevel: parseInt(currentLevel, 10) || 0,
    remainingPowerpoints: parseInt(totalPowerpoints, 10) || 0,
    remainingCoins: parseInt(totalCoins, 10) || 0,
    details: []
  })
}
